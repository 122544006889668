<template>
  <div>
    <BuscadorReporteCursosInternosEstado :activeTab="activeTab" :activeSubTabCursosInternos="activeSubTabCursosInternos" class="custom-background" @datos-recibidos="actualizarItems" @clear-table="clearTable" :isData="isData"/>
    <v-divider />
    <v-data-table :headers="headers" :items="items.data" :mobile-breakpoint="0" :items-per-page="10">
      
      <template #item.nombre_curso="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" style="width: 100%;">
              <span class="nombre-curso">{{ item.nombre_curso }}</span><br>
              <span class="cantidad-participantes">{{ item.cantidad_participantes }} Participantes</span><br>
            </div>
          </template>
          <span>Curso externo: {{ item.nombre_curso }}</span>
        </v-tooltip>
      </template>
    
      <template #item.participantes="{ item }">
        <v-data-table :headers="sub_headers" :items="item.participantes" :mobile-breakpoint="0" :items-per-page="10000" hide-default-footer class="elevation-1">
          <template #item.nombre="{ item }">
            <span class="nombre-curso">{{ item.nombre }} {{ item.apellido }}</span> <br>
            <span>{{ item.rut }}</span>
          </template>
          <template #item.cargo="{ item }">
            <span>{{ item.cargo }}</span>
          </template>
          <template v-slot:item.fecha_realizacion="{ item }">
            <span v-if="item.fecha_realizacion">{{ formatDate(item.fecha_realizacion) }}</span>
          </template>
          <template v-slot:item.fecha_vencimiento="{ item }">
            <v-chip 
              v-if="item.fecha_vencimiento"
              :style="calculateChipColor(item.fecha_vencimiento)" 
              class="chip-style"
            >
              <span v-if="item.fecha_vencimiento">{{ formatDate(item.fecha_vencimiento) }}</span> 
            </v-chip>
          </template>
        </v-data-table>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment'
import 'moment/locale/es' // Importar el idioma español
import BuscadorReporteCursosInternosEstado from '@/shared/components/BuscadorReporteCursosInternosEstado'
import { axiosHelper } from '@/helpers'

moment.locale('es') // Establecer el idioma globalmente

export default {
  props: {
    activeTab: {
      type: Number,
      required: true
    },
    activeSubTabCursosInternos:{
      type: Number,
    }
  },
  data() {
    return {
      headers: [
        { text: 'Curso', value: 'nombre_curso' },
        { text: 'Participantes', value: 'participantes', sortable: false },
      ],
      items: {},
      isData:false,
      colors: {
          red: 'white',
          yellow: 'black',
          green: 'white'
      },
      sub_headers:[
        { text: 'Participante', value: 'nombre' },
        { text: 'Cargo', value: 'cargo' },
        { text: 'Realizacion', value: 'fecha_realizacion' },
        { text: 'Vencimiento', value: 'fecha_vencimiento' ,align: 'right'},
      ]
    };
  },
  components: {
    BuscadorReporteCursosInternosEstado,
  },
  methods: {
    actualizarItems(nuevosItems) {
      this.items = nuevosItems;
      if (Object.keys(nuevosItems).length === 0 && nuevosItems.constructor === Object) {
        this.isData = false;
      } else {
        this.isData = true;
      }
    },
    clearTable() {
      this.items = {};
      this.isData = false; // hace desaparecer los botones de descarga
    },
    formatDate(date) {
      return moment(date).format('D MMMM YYYY');
    },
    calculateChipColor(expirationDate) {
      const today = new Date();
      const expiry = new Date(expirationDate);
      const diffTime = Math.abs(expiry - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      const commonStyles = {
          margin: '5px',
          minWidth: 'auto', 
          maxWidth: '100%', 
          overflow: 'hidden',
          'text-overflow': 'ellipsis',
          'white-space': 'nowrap'
      };
      if (expiry < today) {
          return { backgroundColor: 'red', color: this.colors.red, ...commonStyles };

      // TODO 21 debe ser una valor almacenado en base de datos.
      } else if (diffDays <= 21) {
          return { backgroundColor: 'yellow', color: this.colors.yellow, ...commonStyles };
      } else {
          return { backgroundColor: 'green', color: this.colors.green, ...commonStyles };
      }
    }
  }
}
</script>

<style scoped>
  .custom-background {
    background-color: #d9e6e9;
  }
  .top-align {
    vertical-align: top !important;
  }
  .chip-style {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nombre-curso {
    color: rgb(2, 2, 10);
    font-weight: bold;
  }
  .cantidad-participantes {
      color: rgb(92, 102, 92);
  }
</style>
