import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import logo from '@/assets/logo.jpg'; // logo para incluir en los reportes

class AbstractDocPdf{
    constructor() {
        if (new.target === AbstractDocPdf) {
            throw new Error("AbstractDocPdf es una clase abstracta y no puede ser instanciada directamente.");
        }
        this.doc = new jsPDF();
        this.startY = 0;
        this.anchoLinea = { maxWidth: 170 };
        this.lineHeight = 5;
        this.margin = 20;
        this.headerHeight = 10;
        this.pageWidth = this.doc.internal.pageSize.getWidth();
        this.pageHeight = this.doc.internal.pageSize.getHeight();
        this.contentWidth = this.pageWidth - this.margin*2; // Ancho útil del contenido
        this.startContentY = this.margin + this.headerHeight;
        this.fechaActual = new Date();
    }
    generarPdf() {
        throw new Error('Este método debe ser implementado por una subclase');
    }
    addHeader(){
        const pageCount = this.doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            this.doc.setPage(i);
            this.doc.addImage(logo, 'PNG', this.margin + 150, this.margin / 2, 20, 10); // Ajusta la posición según el margen
        }
    }
    addFooter(){
        const pageCount = this.doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            this.doc.setPage(i);
            this.doc.setFontSize(10);
            this.doc.setFont('helvetica', 'normal');
            const text = `${this.fechaActual} Página ${i} de ${pageCount}`;
            const textWidth = this.doc.getTextWidth(text);
            this.doc.text(text, this.pageWidth - this.margin - textWidth, this.pageHeight - this.margin / 2);
        }
    }
    saveDocument(docName){
        this.doc.save(docName);
    }
    setTituloDocumento(titulo){
        this.doc.setFontSize(22);
        this.doc.setFont('helvetica', 'bold');
        const tituloY = this.startContentY; // Posición Y del título considerando el margen y el encabezado
        const tit = this.doc.splitTextToSize(titulo, this.pageWidth - this.margin * 2);
        this.doc.text(tit, this.margin, tituloY,this.anchoLinea);
        this.startY = tituloY + (tit.length * 10);
    }
    setLine(){
        this.doc.setLineWidth(0.1);
        this.doc.line(this.margin, this.startY, this.pageWidth - this.margin, this.startY); // Coordenadas (x1, y1, x2, y2)
        this.startY += 5;
    }
    setHeader(encabezado){
        this.verificarFinPagina();
        this.setLine();
        this.doc.setFontSize(15);
        this.doc.setFont('helvetica', 'bold');
        this.doc.text(encabezado, this.margin, this.startY);
        this.startY +=2;
        this.setLine();
    }
    verificarFinPagina(){
        if (this.startY + this.lineHeight > this.pageHeight - this.margin) {
            this.doc.addPage();
            this.startY = this.startContentY;
        }
    }
    escribirTexto(text, negrita=false){
        if(negrita){
            this.doc.setFont("times", "bold");
        }
        const lines = this.doc.splitTextToSize(text, this.contentWidth); // Divide el texto para ajustarse al ancho útil
        let cursorY = this.startY; // Posición inicial en Y
        lines.forEach(line => {
            if (cursorY + this.lineHeight > this.pageHeight - this.margin) {
                this.doc.addPage(); // Salta a una nueva página si se excede el espacio vertical
                cursorY = this.startContentY; // Reinicia el cursor en la nueva página
            }
            this.doc.text(line, this.margin, cursorY);
            cursorY += this.lineHeight;
        });
        this.startY = cursorY;
    }
    // theme can be : 'striped', 'grid' or 'plain'
    agregarDatosAtabla(body,theme,booleanhavehead,headerTable){
        //
        let configTable = {
            body: body,
            startY: this.startY + 2,
            margin: { top: this.startContentY, left: this.margin, right: this.margin },
            // didDrawPage: this.addHeader,
            headStyles: {
                halign: 'center'
            },
            theme: theme,
            columnStyles: {
                1: { halign: 'right' }
            }
        }
        if(booleanhavehead){
            configTable['head'] = [headerTable];
        }
        this.doc.autoTable(configTable);
        this.startY = this.doc.lastAutoTable.finalY + 10;
    }
    finalizarDocumento(nombreDocumento){
        this.addHeader();
        this.addFooter();
        this.saveDocument(nombreDocumento);
    }
} 

class DocPdfRendimientoCursoResumen extends AbstractDocPdf{
    constructor(){ 
        super();
    }
    addResumenCurso(rendimiento_curso){
        var body = [
            {name:"Nombre Curso",               valor:rendimiento_curso.curso.nombre},
            // {name:"Activo",                     valor:rendimiento_curso.grupo.is_activo ? 'Si' : 'No'},
            // {name:"Inicio",                     valor:moment(rendimiento_curso.grupo.fecha_inicio).format('DD/MM/YYYY')},
            // {name:"Término",                    valor:moment(rendimiento_curso.grupo.fecha_termino).format('DD/MM/YYYY')},
            {name:"Categorias del curso",       valor:rendimiento_curso.curso.categorias.join(',')},
            {name:"Autor Curso",                valor:rendimiento_curso.curso.autor +" ("+ rendimiento_curso.curso.gerencia.nombre + ")"},
            {name:"Empresa",                    valor:rendimiento_curso.curso.empresa.nombre},
            {name:"N° Participantes",           valor: rendimiento_curso.cantidad_participantes},
            // {name:"Grupo de acceso",            valor:rendimiento_curso.grupo.nombre},
            // {name:"Evaluador",                  valor:rendimiento_curso.grupo.evaluador}
        ];
        this.agregarDatosAtabla(body,'grid',false);
        if(rendimiento_curso.evaluaciones.length > 0) {
            rendimiento_curso.evaluaciones.forEach((item,index) => {
                this.setHeader("Evaluación: " + item.nombre);
                const body = item.preguntas.map(preguntas => [
                    preguntas.texto,
                    preguntas.cantidad_incorrectas
                ]);
                const headerTable= ['Pregunta', 'veces incorrectas'];
                this.agregarDatosAtabla(body,'grid',true,headerTable);
            });
        }
    }
    generarPdf(titleDocument, rendimiento_curso){
        this.setTituloDocumento(titleDocument);
        this.addResumenCurso(rendimiento_curso);
        const documentName = 'Rendimiento_curso_' + rendimiento_curso.curso.nombre + '.pdf';
        this.finalizarDocumento(documentName.replace(/ /g, "_"));
    }
}

class DocPdfRendimientoCursoInternoIndividual extends AbstractDocPdf{
    constructor(){ 
        super();
    }
    addDatosParticipante(persona){
        this.setHeader("DATOS DEL PARTICIPANTE");
        var body = [
            {name:"Nombre",                 valor:persona.first_name + ' ' + persona.last_name},
            {name:"Rut",                    valor:persona.rut},
            {name:"Cargo",                  valor:persona.gerencia.nombre},
            {name:"Empresa",                valor:persona.empresa.nombre}
        ];
        this.agregarDatosAtabla(body,'grid',false);
    }
    addIndicadoresParticipante(evaluaciones,rendimiento){
        this.setHeader("INDICADORES");
        const  body = [
            ["Evaluaciones realizadas",               String(evaluaciones.rel_aprob.toFixed(2)) + " %"],
            ["Evaluaciones no realizadas",            String(evaluaciones.rel_inv.toFixed(2)) + " %"],
            ["Respuestas correctas",                  String(rendimiento.rel_correctas.toFixed(2)) + " %"],
            ["Respuestas incorrectas",                String(rendimiento.rel_incorrectas.toFixed(2)) + " %"]
        ];
        const headerTable= ['Parámetro', 'Porcentaje'];
        this.agregarDatosAtabla(body,'grid',true,headerTable);
    }
    addEstadoEvaluacionesParticipante(evaluaciones){
        this.setHeader("ESTADO DE LAS EVALUACIONES");
        const body = evaluaciones.logs.map(log => [
            log.evaluacion.nombre,
            log.curso,
            log.is_completado ? moment(log.fecha_inicio).format('DD/MM/YYYY HH:mm:ss') + ' hrs' : '',
            log.is_completado ? moment(log.fecha_termino).format('DD/MM/YYYY HH:mm:ss') + ' hrs': '',
            log.is_completado ? 'completado':'pendiente'
        ]);
        const headerTable = ['Evaluación', 'Curso','Inicio','Término','estado'];
        this.agregarDatosAtabla(body,'grid',true,headerTable);

    }
    // addTiempoRespuestaParticipante(tiempo){
    //     this.setHeader("TIEMPO DE RESPUESTA");
    //     if(tiempo.logs.length > 0){
    //         let body = [];
    //         tiempo.logs.forEach((item,index)=>{
    //             const tiempo_data = item.puntajes.map(curso => [
    //                 curso.curso,
    //                 moment(curso.finalizado).format("DD/MM/YYYY HH:mm:ss"),
    //                 curso.tag
    //             ]);
    //             body.push(...tiempo_data);
    //         });
    //         const headerTable = ['Curso','Realización','estado'];
    //         this.agregarDatosAtabla(body,'grid',true,headerTable);
    //     }
    // }
    addDetallePorCurso(rendimiento){
        this.setHeader("DETALLE POR EVALUACIONES COMPLETADAS");
        if(rendimiento.logs.length > 0) {
            rendimiento.logs.forEach((item,index) => {
                const grupoAcceso = item.grupo;
                // itera sobre las evaluaciones de cada grupo.
                var registros = item.registros;
                registros.forEach((item,index)=> {
                    this.escribirTexto("Evaluación: "+item.evaluacion.nombre,true);
                    var body = [
                        {name:"Curso",                  valor:item.evaluacion.curso},
                        {name:"Grupo de acceso",        valor:grupoAcceso},
                        {name:"Cantidad de preguntas",  valor:item.respuestas.length},
                        {name:"Total desaciertos",      valor:item.total_incorrectas},
                        {name:"Tiempo (HH:MM:SS)",      valor:item.duracion.split(".")[0]},
                    ];
                    this.agregarDatosAtabla(body,'grid',false);
                    const body2 = item.respuestas.map(respuesta => [
                        respuesta.pregunta__texto,
                        respuesta.incorrectas
                    ]);
                    const headerTable = ['Pregunta', 'Desaciertos'];
                    this.agregarDatosAtabla(body2,'striped',true,headerTable);
                });
            });
        }
    }
    generarPdf(titleDocument, data){
        this.setTituloDocumento(titleDocument);
        this.addDatosParticipante(data.persona);
        this.addIndicadoresParticipante(data.evaluaciones,data.rendimiento);
        this.addEstadoEvaluacionesParticipante(data.evaluaciones);
        // this.addTiempoRespuestaParticipante(data.tiempo);
        this.addDetallePorCurso(data.rendimiento);
        const documentName = data.persona.first_name + '_' + data.persona.last_name + '_' + data.persona.rut + '.pdf';
        this.finalizarDocumento(documentName.replace(/ /g, "_"));
    }
}

export {DocPdfRendimientoCursoInternoIndividual,DocPdfRendimientoCursoResumen}